<template>
  <Layout>
    <div class="bg-gray-100 flex-grow">
      <div class="w-full max-w-lg relative mx-auto py-8 sm:py-16 px-4">
        <CandidateMagicLink />
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@layouts/main.vue'
import CandidateMagicLink from '@components/Auth/CandidateMagicLink'

export default {
  components: {
    Layout,
    CandidateMagicLink
  }
}
</script>
