<template>
  <div :class="['flex flex-col min-h-screen bg-primary']">
    <TrialBanner />
    <DiscountBanner v-if="$usesFeature('discount-banner')" />
    <Header />
    <slot />
  </div>
</template>

<script>
import DiscountBanner from '@components/DiscountBanner'
import TrialBanner from '@components/TrialBanner'
import Header from '@components/Header'

export default {
  components: {
    DiscountBanner,
    TrialBanner,
    Header
  }
}
</script>
