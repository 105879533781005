<template>
  <div v-if="canSignIn">
    <Loader v-if="isProcessing" />
  </div>
  <div v-else>
    <h1 class="text-2xl md:text-3xl xl:text-4xl">
      Sorry, something’s gone wrong
    </h1>
    <p>
      Try using the magic link again, or <router-link
        :to="{ name: 'sign-in' }"
        class="text-base cta-link font-medium"
      >
        request a new one
      </router-link>
    </p>
  </div>
</template>

<script>
import Loader from '@components/Loader'
import candidateWizardApi from '@api/candidateWizard'

import { setLocalStorage } from '@utils/localStorage'

export default {
  components: {
    Loader
  },

  data() {
    return {
      isProcessing: true
    }
  },

  computed: {
    /**
     * @return {string}
     */
    id() {
      return this.$route.query.id
    },

    /**
     * @return {string}
     */
    token() {
      return this.$route.query.token
    },

    /**
     * @return {string}
     */
    ghost() {
      return this.$route.query.ghost
    },

    /**
     * @return {Boolean}
     */
    canSignIn() {
      return this.id && this.token
    }
  },

  created() {
    this.signIn()
  },

  methods: {
    signIn() {
      if (!this.canSignIn) {
        return
      }
      return candidateWizardApi.token(this.id, this.token)
        .then((response) => {
          setLocalStorage('candidateWizard.uuid', response.data.uuid)
          setLocalStorage('candidateWizard.password', response.data.secret)
          this.$router.push({ name: 'candidate-wizard-dashboard' })
        })
    }
  }
}
</script>
